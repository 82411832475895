<script setup>

import {onMounted, reactive, ref, watch} from "vue"
import request from "../request"
import {useRouter} from "vue-router"
import {Snackbar} from "@varlet/ui"
import statics from "../static";

const router = useRouter()

const props = defineProps({
})

const showModal = ref(false)
const loading = ref(false)

let stripe, paymentElement, elements, clientSecret = null

onMounted(() => {
	if (showModal.value) {
		createSetupIntent()
	}
})

const createSetupIntent = () => {
	loading.value = true
	request.get(process.env.VUE_APP_BASE_URL + '/create-setup-intent')
		.then(response => {
			loading.value = false

			stripe = Stripe(process.env.VUE_APP_STRIPE_KEY)
			clientSecret = response.client_secret
			elements = stripe.elements({
				clientSecret,
				appearance: {
					theme: "night"
				}
			})
			const paymentElementOptions = {
				layout: "tabs"
			}

			paymentElement = elements.create("payment", paymentElementOptions)
			paymentElement.mount("#payment-element")
		})
		.catch(error => {
			console.log(error)
			loading.value = false
		})
}

const onBeforeClose = (action, done) => {
	if (action === 'confirm') {
		elements.submit()
		stripe.confirmSetup({
			elements: elements,
			clientSecret: clientSecret,
			redirect: "if_required"
		}).then(result => {
			const paymentMethod = result.setupIntent.payment_method
			console.log(result.setupIntent)
			if (result.error) {
				Snackbar.error(result.error.message)
			} else {
				request.post(process.env.VUE_APP_BASE_URL + '/payment-methods', {
					payment_method_id: paymentMethod
				}).then(response => {
					if (response.error) {
						Snackbar.error(response.error.message)
					} else {
						Snackbar.success('Payment method added')
						done()
					}
				})
			}
		})
	} else {
		done()
	}
}

watch(showModal, async (newVal, oldVal) => {
	if (newVal && !clientSecret) {
		createSetupIntent()
	}
})

defineExpose({
	showModal
})

</script>

<template>
	<var-style-provider :style-vars="{ '--dialog-width': '420px', '--dialog-message-line-height': '22px', '--card-background': 'var(--color-body)' }">
		<var-dialog
			v-model:show="showModal"
			confirm-button-text="Add"
			cancel-button-text="Cancel"
			@before-close="onBeforeClose">
			<template #title>
				Add Payment Method
			</template>

			<var-loading :loading="loading">
				<template #default>
					<div id="payment-element"></div>
				</template>
			</var-loading>
		</var-dialog>
	</var-style-provider>
</template>

<style scoped>

</style>