<script setup>

import PageHeader from "../components/PageHeader.vue"
import ActionableDataTable from "../components/ActionableDataTable.vue"
import request from "../request"
import {onMounted, ref} from "vue"
import AddPaymentMethodModal from "../components/AddPaymentMethodModal.vue";

const dataSource = (page, rpp) => new Promise((resolve, reject) => {
	request.get(process.env.VUE_APP_BASE_URL + '/payment-methods')
		.then(response => resolve(response))
		.catch(error => reject(error))
})
const headerMapping = {
	brand: "Brand",
	last4: "Last 4 Digits",
	exp_month: "Exp Month",
	exp_year: "Exp Year",
	default: "Default",
}

const addPaymentMethodModal = ref(null)
const openModal = () => {
	addPaymentMethodModal.value.showModal = true
}

const editAtRow = (row) => {
	console.log(row)
}

const detailAtRow = (row) => {
	console.log(row)
}

const deleteAtRow = (row) => {
	console.log(row)
}

const loading = false
const showModal = false

defineExpose({
	showModal
})
</script>

<template>
	<page-header
		name="Payment Methods"
		button-name="Add Payment Method"
		:on-create-click="openModal"
	/>
	<actionable-data-table
		ref="dataTable"
		:data-source="dataSource"
		:paginate="false"
		:edit-at-row="editAtRow"
		:detail-at-row="detailAtRow"
		:delete-at-row="deleteAtRow"
		:header-mapping="headerMapping"
		:rpp="10"
	/>

	<add-payment-method-modal ref="addPaymentMethodModal" />
</template>

<style scoped>

</style>